import { ContentPage } from '../../components'
import { SiteCorePath } from '../../constants'
import { SitecoreService } from '../../services/SitecoreService'
import type { Context } from '../../types'
import { isClientOutreachFlow } from '../../utils/check'
import { log } from '../../utils/log'
import { serverRedirectExternal } from '../../utils/redirect'

export const getServerSideProps = async (ctx: Context) => {
  const page = isClientOutreachFlow(ctx)
    ? SiteCorePath.ClientOutreachError
    : SiteCorePath.VerificationError

  try {
    log.info(ctx, 'Showing error page')

    const content = await SitecoreService.getContent(ctx, page)

    return { props: { content } }
  } catch {
    return serverRedirectExternal(
      ctx,
      SitecoreService.getRedirectUrl(ctx, page),
    )
  }
}

export default ContentPage
